<template>
  <policeCheckCertificate
    :urlCertification="urlCertification"
  ></policeCheckCertificate>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import policeCheckCertificate from "../../components/policeCheck/policeCheckCertificate.vue";
export default {
  name: "policeCheckCertificateCom",
  components: {
    policeCheckCertificate,
  },

  data() {
    return {
      checkId: encodeDecodeService.decode(this.$route.params.checkId),
      urlCertification: "",
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle"]),
  },
  created() {
    this.fetchPoliceCheckSingle(this.checkId);
  },
  watch: {
    getPoliceCheckSingle(val) {
      if (val) {
        this.urlCertification = val.police_check_certificate_from_nss;
      }
    },
  },
};
</script>
